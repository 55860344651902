import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import "../../../../../node_modules/swiper/swiper.scss";

const Terms = () => {
  //meta title
  document.title = "Crescent Exchange | Home";

  return (
    <React.Fragment>
      <section className="section bg-white" id="terms">
        <Container fluid={false}>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <h4>Terms of Service</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div>
                    <p className="sub-header">
                      1. By using our money-changing and remittance services,
                      you agree to comply with the following terms and
                      conditions.
                    </p>
                    <p>
                      2. We reserve the right to modify or terminate our
                      services at any time without prior notice.
                    </p>
                    <p>
                      3. You are responsible for providing accurate and
                      up-to-date information during the registration and
                      transaction process.
                    </p>

                    <p>
                      4. Our services are subject to applicable laws and
                      regulations regarding money-changing and remittance.
                    </p>

                    <p>
                      5. The exchange rates provided are based on real-time
                      market conditions and may be subject to fluctuations.
                    </p>

                    <p className="mb-0">
                      6. We strive to ensure the security and confidentiality of
                      your personal information as outlined in our Privacy
                      Policy.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Terms;
