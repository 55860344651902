import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col } from "reactstrap";

const CardBox = (props) => {
  return (
    <React.Fragment>
      {props.coins.map((coin, key) => (
        <Col md="4" key={key}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span
                    className={
                      "avatar-title rounded-circle bg-" +
                      coin.color +
                      "-subtle text-" +
                      coin.color +
                      " font-size-18"
                    }
                  >
                    <i className={coin.icon} />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <p
                    className="text-muted"
                    style={{ marginBottom: "5px", lineHeight: "1.6" }}
                  >
                    {coin.title}
                  </p>
                  <div className="d-flex align-items-baseline">
                    <i className="mdi mdi-circle-medium text-success me-1"></i>
                    <h5 className="mb-0">{coin.value}</h5>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

CardBox.propTypes = {
  coins: PropTypes.array,
};

export default CardBox;
