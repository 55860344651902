import React, { useEffect, useState } from "react";
import withRouter from "../components/Common/withRouter";
import Navbar from "./Landing/Navbar/Navbar";
import Section from "./Landing/HeroSection/Section";
import CardsMini from "./Landing/HeroSection/cards-mini";
import AboutUs from "./Landing/AboutUs/about-us";
import Features from "./Landing/Features/features";
import FAQs from "./Landing/Faqs/FAQs";
import Contact from "./Landing/Contact/Contact";
import Terms from "./Landing/Terms/terms";
import Policy from "./Landing/Policy/policy";
import Footer from "./Landing/Footer/footer";

const Home = () => {
  //meta title
  document.title = "Crescent Exchange | Home";

  const [imglight, setimglight] = useState(true);
  const [navClass, setnavClass] = useState("");

  // Use ComponentDidMount
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 80) {
      setimglight(false);
      setnavClass("nav-sticky");
    } else {
      setimglight(true);
      setnavClass("");
    }
  }

  return (
    <React.Fragment>
      <Navbar navClass={navClass} imglight={imglight} />
      <Section />
      <CardsMini />
      <AboutUs />
      <Features />
      <FAQs />
      <Contact />
      <Terms />
      <Policy />
      <Footer />
    </React.Fragment>
  );
};

export default withRouter(Home);
