import React from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

const Contact = () => {
  document.title = "Crescent Exchange | Home";

  return (
    <React.Fragment>
      <section className="section bg-white" id="contact">
        <Container fluid={false}>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>Contact Us</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Location & Contact Info
                  </CardTitle>
                  <p className="text-muted mb-4">
                    If you have any inquiries or require assistance, please
                    don't hesitate to get in touch with us:
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Company :</th>
                          <td>Crescent Exchange Pte. Ltd.</td>
                        </tr>
                        <tr>
                          <th scope="row">Address :</th>
                          <td>
                            11 Collyer Quay, The Arcade #03-07B <br /> Singapore
                            049317
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>+65 8590 0090 | +65 9055 5575</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>
                            <Link
                              to="mailto:support@crescentexchange.com"
                              className="text-primary"
                            >
                              support@crescentexchange.com{" "}
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
