import PropTypes from "prop-types";
import React from "react";

const FeatureBox = (props) => {
  return (
    <React.Fragment>
      <div className="mt-4 mt-md-auto mx-3">
        <div className="d-flex align-items-center mb-2">
          <div className="features-number font-weight-semibold display-4 me-3">
            {props.num}
          </div>
          <div class="flex-container">
            <div class="first-div service-title fw-bold fs-4">
              {props.title}
            </div>
            <div class="second-div sub-title fw-bold fs-5">
              {props.subtitle}
            </div>
          </div>
        </div>
        <p className="text-muted">{props.desc}</p>
        <div className="text-muted mt-4">
          {props.features.map((feature, key) => (
            <p key={key} className={feature.id === 1 ? "mb-2" : ""}>
              <i className="mdi mdi-circle-medium text-success me-1" />
              {feature.desc}
            </p>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

FeatureBox.propTypes = {
  desc: PropTypes.any,
  features: PropTypes.array,
  num: PropTypes.string,
  title: PropTypes.string,
};

export default FeatureBox;
