import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import {
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import ScrollspyNav from "./scrollSpy";
import logodark from "./../../../../images/logo-dark.png";
import logolight from "./../../../../images/logo-light.png";
import ReactDOM from "react-dom";
import { config } from "../../../../config";

const navItems = [
  { id: 1, idnm: "home", navheading: "Home" },
  { id: 2, idnm: "about", navheading: "About" },
  { id: 3, idnm: "features", navheading: "Services" },
  { id: 4, idnm: "faqs", navheading: "FAQs" },
  { id: 5, idnm: "contact", navheading: "Contact" },
  { id: 6, idnm: "terms", navheading: "Terms" },
  { id: 7, idnm: "policy", navheading: "Policy" },
];

const login = [
  { label: "Customer", url: config.customerLoginUrl, value: 1 },
  { label: "Agent", url: config.agentLoginUrl, value: 2 },
];

const Navbar_Page = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navbarRef = useRef(null);
  const dropdownMenuRef = useRef(null); // Reference for DropdownMenu

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleClickOutside = (event) => {
      // If the click is outside the navbar and dropdown, close the menu and dropdown
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        dropdownMenuRef.current &&
        !ReactDOM.findDOMNode(dropdownMenuRef.current).contains(event.target) // Using findDOMNode for the dropdown
      ) {
        setIsOpenMenu(false);
        setDropdownOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Store all NavigationbaFr Id into TargetID variable(Used for Scrollspy)
  let TargetId = navItems.map((item) => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      <nav
        ref={navbarRef}
        className={
          "navbar navbar-expand-lg navigation fixed-top sticky " +
          props.navClass
        }
      >
        <Container>
          <Link className="navbar-logo" to="/">
            {windowWidth < 991 ? (
              <img
                src={logodark}
                alt="Dark Logo"
                height="19"
                className="logo logo-dark"
              />
            ) : props.imglight !== true ? (
              <img
                src={logodark}
                alt="Dark Logo"
                height="19"
                className="logo logo-dark"
              />
            ) : (
              <img
                src={logolight}
                alt="Light Logo"
                height="19"
                className="logo logo-light"
              />
            )}
          </Link>

          <NavbarToggler
            className="p-0"
            onClick={() => {
              setIsOpenMenu(!isOpenMenu);
            }}
          >
            <i className="fa fa-fw fa-bars" />
          </NavbarToggler>

          <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
            <ScrollspyNav
              scrollTargetIds={TargetId}
              scrollDuration="800"
              headerBackground="true"
              activeNavClass="active"
              className="navbar-collapse"
            >
              <Nav className="ms-auto navbar-nav" id="topnav-menu">
                {navItems.map((item, key) => (
                  <NavItem
                    key={key}
                    className={item.navheading === "Home" ? "active" : ""}
                  >
                    <NavLink href={"#" + item.idnm}> {item.navheading}</NavLink>
                  </NavItem>
                ))}
              </Nav>
            </ScrollspyNav>
            <div className="ms-lg-2">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => {
                  if (windowWidth < 991) {
                    setDropdownOpen(!dropdownOpen);
                  }
                }}
                onMouseEnter={() => {
                  if (windowWidth >= 991) {
                    setDropdownOpen(true);
                  }
                }}
                onMouseLeave={() => {
                  if (windowWidth >= 991) {
                    setDropdownOpen(false);
                  }
                }}
              >
                <DropdownToggle
                  tag={Link}
                  className="btn btn-outline-success w-xs"
                  onClick={(e) => {
                    if (windowWidth < 991) {
                      e.preventDefault();
                      setDropdownOpen(!dropdownOpen);
                    }
                  }}
                >
                  Login
                </DropdownToggle>
                <DropdownMenu ref={dropdownMenuRef}>
                  {login.map((x) => (
                    <DropdownItem key={x.value}>
                      <Link to={x.url} className="text-secondary">
                        {x.label}
                      </Link>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

Navbar_Page.propTypes = {
  imglight: PropTypes.any,
  navClass: PropTypes.string,
};

export default Navbar_Page;
