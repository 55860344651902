import React from "react";
import { Container, Row, Col } from "reactstrap";

const Section = () => {
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="text-white-50 mx-3">
                <h1 className="text-white fw-semibold mb-3 hero-title">
                  Welcome to Crescent Exchange
                </h1>
                <p className="font-size-14">
                  Your gateway to secure, efficient, and cost-effective
                  solutions for individuals and businesses alike. With our
                  user-friendly platform, customers can easily exchange
                  currencies, transfer funds internationally, and stay updated
                  with the latest exchange rates.
                </p>

                <div className="d-flex flex-wrap gap-2 mt-4">
                  <a href="#features" className="btn btn-success">
                    Explore Services
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={5} md={8} sm={10} className="ms-lg-auto"></Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
