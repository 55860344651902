import React from "react";
import { Row, Col } from "reactstrap";

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col
          lg="12"
          md="12"
          className="d-flex justify-content-center justify-content-md-end"
        >
          <p className="mb-2">{new Date().getFullYear()} © Cresent Exchange</p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FooterLink;
